import React, {useEffect, useState} from "react";
import AttendanceMobileReal from "../control/attendancemobilereal";

const AttendanceTable : React.FC<any> = (props) => {

    return (
        <div>
            <AttendanceMobileReal />
        </div>
    );
}

export default AttendanceTable;